/* stylelint-disable */

@media print {
  @page {
    margin: 0;
  }
  html,
  body {
    width: 100% !important;
    height: 100% !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
  }
  .clearUp {
    display: table;
    page-break-inside: auto;
    width: initial;
  }
  .clearUp > div {
    display: table-row;
    break-inside: avoid;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .opacity {
    opacity: 1 !important;
    display: table-caption !important;
    width: 50mm !important ;
  }
  .col {
    padding-right: 5mm !important;
    min-width: 70mm !important;
    display: table-row;
  }
  .noHeight {
    width: initial !important;
    height: initial !important;
    page-break-before: always;
  }

  .printVisible {
    display: block !important;
  }

  .printVisibleFlex {
    display: flex !important;
  }

  .noprint,
  .no-print,
  .no-print * {
    display: none !important;
    height: 0;
    margin: 0 !important;
  }

  .printfull {
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    position: unset;
  }

  .noLeft {
    left: 0 !important;
    position: relative !important;
  }
  .header-print {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 7px 20px;
    background: #ffffff;
    width: 100%;
  }
  table {
    width: 100%;
  }
  .page {
    page-break-after: always;
  }
  .printheaderPlanning {
    display: flex !important;
    flex-direction: column;
  }
  .printHidden {
    visibility: hidden !important;
  }
  .stylePrint {
    padding-bottom: 0 !important;
    height: auto !important;
  }

  .current {
    flex: 1 !important;
  }

  .fullWidthPrint {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
  }
  .main-screen {
    display: flex !important;
  }

  .header {
    height: 55px;
  }
  .header-print {
    position: fixed;
    top: 0mm;
    z-index: 9;
    width: 100%;
  }
  .page-header-space {
    height: 60px;
  }
  .thead {
    display: table-header-group !important;
  }
  .tr {
    grid-template-columns: '6fr repeat(5, 1fr)';
  }

  span {
    display: block !important;
  }

  .detail,
  .bloc {
    break-inside: avoid;
    page-break-inside: avoid;
    display: table;
    position: relative;
  }

  .devis5s {
    gap: 20px;
    display: grid !important;
  }

  .devis5s > div {
    width: 100% !important;
  }
}


